/* src/App.css */



.backgrounHeroImage{
background-color: rgb(18, 17, 17);
background-size: cover;
border-radius: 0 0 20px 40px;

}




/* Animate bounce */
@keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
      transform: translateY(0);
    }
    40% {
      transform: translateY(-30px);
    }
    60% {
      transform: translateY(-15px);
    }
  }
  
  .animate-bounce {
    animation: bounce 1.5s infinite;
  }
  