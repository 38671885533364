.carousel-container {
  position: relative;
  overflow: hidden;
  width: 100%;
}

.carousel-inner {
  display: flex;
  will-change: transform;
  transition: transform 0.5s ease-in-out;
}

.carousel-item {
  flex-shrink: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.product-card {
  width: 70vw; /* Adjust based on your layout */
  height: 300px; /* Adjust based on your layout */
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden; /* Ensure no overflow */
}

.product-card img {
  width: 100%;
  height: 100%;
  object-fit: contain; /* Scale the image to fit while maintaining aspect ratio */
  border-radius: 12px;
}

.product-card .absolute {
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 8px;
  background: rgba(0, 0, 0, 0.75);
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
}

button {

  transition: background-color 0.3s ease;
}

button:hover {
  background-color: #e0e0e0;
}
